import React, { useEffect } from 'react';
import { useLocation, NavLink } from 'react-router-dom';
import './profile.css';
import { useTranslation } from 'react-i18next';

const ManagementUserSettingWrapper = ({ children }) => {
  const location = useLocation();
  const { t } = useTranslation();
  const stateData = location.state;

  useEffect(() => {
    if (stateData.userType === 'staff') {
      window.location.href = '/staff_login';
    }
  }, [stateData]);

  return (
    <div className="container">
      <div>
        <div className="d-flex">
          <NavLink
            to="/users/all"
            className={({ isActive, isPending }) =>
              isPending
                ? 'pending'
                : isActive
                ? 'user-setting-active user-nav-link me-3'
                : 'user-nav-link me-3'
            }
            state={stateData}
          >
            {t('All Users')}
          </NavLink>

          <NavLink
            to={'/users/roles'}
            className={({ isActive, isPending }) =>
              isPending
                ? 'pending'
                : isActive
                ? 'user-setting-active user-nav-link me-3'
                : 'user-nav-link me-3'
            }
            state={stateData}
          >
            {t('Roles List')}
          </NavLink>
        </div>
        <div className="mt-4">
          {React.Children.map(children, (child) => {
            if (React.isValidElement(child)) {
              return React.cloneElement(child, { state: stateData });
            }
            return child;
          })}
        </div>
      </div>
    </div>
  );
};

export default ManagementUserSettingWrapper;
