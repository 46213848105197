import { returnError, getToken } from './helperFunc';
import {
  getUserRoleListAction,
  addUserRoleAction,
  assignUserRoleAction,
  editUserRoleAction,
  deleteUserRoleAction,
  getUserPermissionsAction,
  addNewUserAction,
  getUsersListAction,
  updateUserAction,
  updateOutletVisibilityAction,
} from './requestEndpoints';

const SET_MANAGEMENT_LOADING = 'SET_MANAGEMENT_LOADING';
const SET_USER_MANAGEMENT_LOADING2 = 'SET_USER_MANAGEMENT_LOADING2';
const CLEAR_ERROR = 'CLEAR_ERROR';

const ADD_NEW_USER_SUCCESS = 'ADD_NEW_USER_SUCCESS';
const ADD_NEW_USER_FAIL = 'ADD_NEW_USER_FAIL';

const UPDATE_USER_SUCCESS = 'UPDATE_USER_SUCCESS';
const UPDATE_USER_FAIL = 'UPDATE_USER_FAIL';

const GET_USERS_LIST_SUCCESS = 'GET_USERS_LIST_SUCCESS';
const GET_USERS_LIST_FAIL = 'GET_USERS_LIST_FAIL';

const ADD_USER_ROLE_SUCCESS = 'ADD_USER_ROLE_SUCCESS';
const ADD_USER_ROLE_FAIL = 'ADD_USER_ROLE_FAIL';

const ASSIGN_USER_ROLE_SUCCESS = 'ASSIGN_USER_ROLE_SUCCESS';
const ASSIGN_USER_ROLE_FAIL = 'ASSIGN_USER_ROLE_FAIL';

const GET_USER_ROLE_LIST_SUCCESS = 'GET_USER_ROLE_LIST_SUCCESS';
const GET_USER_ROLE_LIST_FAIL = 'GET_USER_ROLE_LIST_FAIL';

const DELETE_USER_ROLE_SUCCESS = 'DELETE_USER_ROLE_SUCCESS';
const DELETE_USER_ROLE_FAIL = 'DELETE_USER_ROLE_FAIL';

const UPDATE_USER_ROLE_SUCCESS = 'UPDATE_USER_ROLE_SUCCESS';
const UPDATE_USER_ROLE_FAIL = 'UPDATE_USER_ROLE_FAIL';

const GET_USER_PERMISSIONS_SUCCESS = 'GET_USER_PERMISSIONS_SUCCESS';
const GET_USER_PERMISSIONS_FAIL = 'GET_USER_PERMISSIONS_FAIL';

const UPDATE_OUTLET_VISIBILITY_SUCCESS = 'UPDATE_OUTLET_VISIBILITY_SUCCESS';
const UPDATE_OUTLET_VISIBILITY_FAIL = 'UPDATE_OUTLET_VISIBILITY_FAIL';

// FOR PRICE SETTING
// const token = getToken(user);
export const addNewUser = (newUser) => async (dispatch, getState) => {
  try {
    dispatch({ type: CLEAR_ERROR });
    dispatch({ type: SET_MANAGEMENT_LOADING });
    const { user } = getState();
    const token = getToken(user);
    const { data } = await addNewUserAction(newUser, token);

    dispatch({ type: ADD_NEW_USER_SUCCESS, payload: data });
  } catch (error) {
    dispatch({ type: ADD_NEW_USER_FAIL, payload: returnError(error) });
  }
};

export const updateUser = (newUser) => async (dispatch, getState) => {
  try {
    dispatch({ type: CLEAR_ERROR });
    dispatch({ type: SET_MANAGEMENT_LOADING });
    const { user } = getState();
    const token = getToken(user);
    const { data } = await updateUserAction(newUser, token);

    dispatch({ type: UPDATE_USER_SUCCESS, payload: data });
  } catch (error) {
    dispatch({ type: UPDATE_USER_FAIL, payload: returnError(error) });
  }
};

export const getUsersList = (operatorId) => async (dispatch, getState) => {
  try {
    dispatch({ type: CLEAR_ERROR });
    dispatch({ type: SET_USER_MANAGEMENT_LOADING2 });
    const { user } = getState();
    const token = getToken(user);
    // console.log('stateData', user);
    const { data } = await getUsersListAction(token, operatorId);

    dispatch({ type: GET_USERS_LIST_SUCCESS, payload: data });
  } catch (error) {
    dispatch({ type: GET_USERS_LIST_FAIL, payload: returnError(error) });
  }
};

export const getUserRoleList = () => async (dispatch, getState) => {
  try {
    dispatch({ type: CLEAR_ERROR });
    dispatch({ type: SET_MANAGEMENT_LOADING });
    const { user } = getState();
    const token = getToken(user);
    // console.log('stateData', user);
    const { data } = await getUserRoleListAction(token);

    dispatch({ type: GET_USER_ROLE_LIST_SUCCESS, payload: data });
  } catch (error) {
    dispatch({ type: GET_USER_ROLE_LIST_FAIL, payload: returnError(error) });
  }
};

export const getUserPermissions = () => async (dispatch, getState) => {
  try {
    dispatch({ type: CLEAR_ERROR });
    dispatch({ type: SET_MANAGEMENT_LOADING });
    const { user } = getState();
    const token = getToken(user);
    // console.log('stateData', user);
    const { data } = await getUserPermissionsAction(token);

    dispatch({ type: GET_USER_PERMISSIONS_SUCCESS, payload: data });
  } catch (error) {
    dispatch({ type: GET_USER_PERMISSIONS_FAIL, payload: returnError(error) });
  }
};

export const addUserRole = (newRole) => async (dispatch, getState) => {
  try {
    dispatch({ type: CLEAR_ERROR });
    dispatch({ type: SET_MANAGEMENT_LOADING });
    const { user } = getState();
    const token = getToken(user);
    const { data } = await addUserRoleAction(newRole, token);

    dispatch({ type: ADD_USER_ROLE_SUCCESS, payload: data });
  } catch (error) {
    dispatch({ type: ADD_USER_ROLE_FAIL, payload: returnError(error) });
  }
};

export const assignUserRole = (newRole) => async (dispatch) => {
  try {
    dispatch({ type: CLEAR_ERROR });
    dispatch({ type: SET_MANAGEMENT_LOADING });

    const { data } = await assignUserRoleAction(newRole);

    dispatch({ type: ASSIGN_USER_ROLE_SUCCESS, payload: data });
  } catch (error) {
    dispatch({ type: ASSIGN_USER_ROLE_FAIL, payload: returnError(error) });
  }
};

export const updateUserRole = (updateData) => async (dispatch, getState) => {
  try {
    dispatch({ type: CLEAR_ERROR });
    dispatch({ type: SET_MANAGEMENT_LOADING });
    const { user } = getState();
    const token = getToken(user);
    const { data } = await editUserRoleAction(updateData, token);

    dispatch({ type: UPDATE_USER_ROLE_SUCCESS, payload: data });
  } catch (error) {
    dispatch({ type: UPDATE_USER_ROLE_FAIL, payload: returnError(error) });
  }
};

export const deleteUserRole = (id) => async (dispatch) => {
  try {
    const { data } = await deleteUserRoleAction(id);

    dispatch({ type: DELETE_USER_ROLE_SUCCESS, payload: data });
  } catch (error) {
    dispatch({ type: DELETE_USER_ROLE_FAIL, payload: returnError(error) });
  }
};

export const updateOutletVisibility =
  (resBody) => async (dispatch, getState) => {
    try {
      dispatch({ type: CLEAR_ERROR });
      dispatch({ type: SET_MANAGEMENT_LOADING });

      const { user } = getState();
      const token = getToken(user);
      const { data } = await updateOutletVisibilityAction(resBody, token);

      dispatch({ type: UPDATE_OUTLET_VISIBILITY_SUCCESS, payload: data });
    } catch (error) {
      dispatch({
        type: UPDATE_OUTLET_VISIBILITY_FAIL,
        payload: returnError(error),
      });
    }
  };

const initialState = {
  language: 'en',
  error: null,
  loading: false,
  roleLists: [],
  role: null,
  permissions: [],
  newUser: '',
  users: [],
};

export const userManagementRoleReducer = function (
  state = initialState,
  action
) {
  switch (action.type) {
    // this is for fetching loading time setter
    case SET_MANAGEMENT_LOADING:
      return {
        ...state,
        loading: true,
      };
    case SET_USER_MANAGEMENT_LOADING2:
      return {
        ...state,
        getLoading: true,
      };
    case CLEAR_ERROR:
      return {
        ...state,
        error: null,
      };

    case ADD_NEW_USER_SUCCESS:
      return {
        ...state,
        newUser: action.payload.data,
        loading: false,
        error: null,
      };

    case UPDATE_USER_SUCCESS:
      return {
        ...state,
        newUser: action.payload.data,
        loading: false,
        error: null,
      };

    case GET_USERS_LIST_SUCCESS:
      return {
        ...state,
        users: action.payload.data,
        getLoading: false,
        error: null,
      };

    case GET_USER_PERMISSIONS_SUCCESS:
      return {
        ...state,
        permissions: action.payload.data,
        loading: false,
        error: null,
      };

    case GET_USER_ROLE_LIST_SUCCESS:
      return {
        ...state,
        roleLists: action.payload.data,
        loading: false,
        error: null,
      };

    case ADD_USER_ROLE_SUCCESS:
      return {
        ...state,
        role: action.payload.data,
        roleLists: [...state.roleLists, action.payload.data],
        loading: false,
        error: null,
        success: true,
      };
    case ASSIGN_USER_ROLE_SUCCESS:
      return {
        ...state,
        role: action.payload.data,
        loading: false,
        error: null,
        success: true,
      };

    case UPDATE_USER_ROLE_SUCCESS:
      return {
        ...state,
        roleUpdated: action.payload.data,
        loading: false,
        error: null,
        success: true,
      };
    case DELETE_USER_ROLE_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null,
        removed: true,
      };

    case UPDATE_OUTLET_VISIBILITY_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null,
        outletVisibility: action.payload.data,
      };

    case GET_USER_ROLE_LIST_FAIL:
    case ASSIGN_USER_ROLE_FAIL:
    case UPDATE_USER_ROLE_FAIL:
    case DELETE_USER_ROLE_FAIL:
    case GET_USER_PERMISSIONS_FAIL:
    case ADD_USER_ROLE_FAIL:
    case ADD_NEW_USER_FAIL:
    case GET_USERS_LIST_FAIL:
    case UPDATE_USER_FAIL:
    case UPDATE_OUTLET_VISIBILITY_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    default:
      return state;
  }
};
